@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/inter/inter.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/inter/inter-italic.ttf');
}

@layer utilities {

  /* Scroll thumb styles */
  .scrollbar::-webkit-scrollbar {
    width: .5rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #27272E;
    border-radius: 1rem;
  }

  .scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #131314;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #3B3B46;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Scroll track styles (Background) */
  .scrollbar::-webkit-scrollbar-track {
    background: #131314;
    border-radius: 4px;
  }

  .scrollbar::-webkit-scrollbar-track:hover,
  .scrollbar::-webkit-scrollbar-track:active {
    background: #131314;
  }
}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #0369a1;
	--ion-color-primary-rgb: 3,105,161;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #035c8e;
	--ion-color-primary-tint: #1c78aa;

	--ion-color-secondary: #0284c7;
	--ion-color-secondary-rgb: 2,132,199;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0274af;
	--ion-color-secondary-tint: #1b90cd;

	--ion-color-tertiary: #0ea5e9;
	--ion-color-tertiary-rgb: 14,165,233;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #0c91cd;
	--ion-color-tertiary-tint: #26aeeb;

	--ion-color-success: #16a34a;
	--ion-color-success-rgb: 22,163,74;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #138f41;
	--ion-color-success-tint: #2dac5c;

	--ion-color-warning: #eab308;
	--ion-color-warning-rgb: 234,179,8;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #ce9e07;
	--ion-color-warning-tint: #ecbb21;

	--ion-color-danger: #dc2626;
	--ion-color-danger-rgb: 220,38,38;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #c22121;
	--ion-color-danger-tint: #e03c3c;

	--ion-color-medium: #71717a;
	--ion-color-medium-rgb: 113,113,122;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #63636b;
	--ion-color-medium-tint: #7f7f87;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

}

// @media (prefers-color-scheme: dark) {
//   /*
//   * Dark Colors
//   * -------------------------------------------
//   */

//   body {
//     --ion-color-primary: #6366f1;
//     --ion-color-primary-rgb: 99, 102, 241;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #575ad4;
//     --ion-color-primary-tint: #7375f2;

//     --ion-color-secondary: #4338ca;
//     --ion-color-secondary-rgb: 67, 56, 202;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #3b31b2;
//     --ion-color-secondary-tint: #564ccf;

//     --ion-color-tertiary: #a5b4fc;
//     --ion-color-tertiary-rgb: 165, 180, 252;
//     --ion-color-tertiary-contrast: #000000;
//     --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//     --ion-color-tertiary-shade: #919ede;
//     --ion-color-tertiary-tint: #aebcfc;

//     --ion-color-success: #16a34a;
//     --ion-color-success-rgb: 22, 163, 74;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 255, 255, 255;
//     --ion-color-success-shade: #138f41;
//     --ion-color-success-tint: #2dac5c;

//     --ion-color-warning: #eab308;
//     --ion-color-warning-rgb: 234, 179, 8;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #ce9e07;
//     --ion-color-warning-tint: #ecbb21;

//     --ion-color-danger: #dc2626;
//     --ion-color-danger-rgb: 220, 38, 38;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #c22121;
//     --ion-color-danger-tint: #e03c3c;

//     --ion-color-medium: #92949c;
//     --ion-color-medium-rgb: 146, 148, 156;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #808289;
//     --ion-color-medium-tint: #9d9fa6;

//     --ion-color-light: #f4f5f8;
//     --ion-color-light-rgb: 244, 245, 248;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0, 0, 0;
//     --ion-color-light-shade: #d7d8da;
//     --ion-color-light-tint: #f5f6f9;

//   }

//   /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */

//   .ios body {
//     --ion-background-color: #f8fafc;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */

//   .md body {
//     --ion-background-color: #f8fafc;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #f8fafc;

//     --ion-toolbar-background: #f8fafc;

//     --ion-tab-bar-background: #f8fafc;

//     --ion-card-background: #f8fafc;
//   }
// }