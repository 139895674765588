/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';


// // swiper
// @import 'swiper/scss';
// @import '@ionic/angular/css/ionic-swiper';

// @import 'animate.css';

@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '~leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
@import '~font-awesome/css/font-awesome.min.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

.LandbotPopup {
  display: none
}

.LandbotPopup.is-open {
  display: block
}

.update-profile-modal .modal-wrapper{
  min-height: 400px;
  height: auto;
}

.my-custom-class {
  --background: rgb(148 163 184);
  --spinner-color:  rgb(30 41 59);

  color: rgb(30 41 59);
}


.loading-icon {
  font-size: 64px;
}


ion-textarea[ng-reflect-auto-grow=true] {
  textarea {
    min-height: 66px;
  }
}
